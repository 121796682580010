import types from "../../statics/types";
const initState = [];

export default function dataLoaderReducer(prevState = initState, action) {
  const { type, data } = action;

  switch (type) {
    case types.reduxTypes.SET_DATA_LOADER:
      return data;
    default:
      return prevState;
  }
}